
.slider {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  z-index: 1;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  opacity: 1;
}

/* Slide text overlay */
.slide-text {
  position: absolute;
top: 50px; /* Adjust the position of the text as needed */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 10px 20px;
  border-radius: 5px;
}

button.prev, button.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2rem;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

button.prev {
  left: 20px;
}

button.next {
  right: 20px;
}

@media (max-width: 768px) {
  button.prev, button.next {
    font-size: 1.5rem;
    padding: 8px;
  }
}
