@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.main-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; /* Full viewport height */
    justify-content: center;
    overflow-x: hidden;
    scrollbar-width: none;
    padding: 0 5%;
    position: relative; /* Ensure that the ::after pseudo-element is positioned relative to this container */
}

.main-container::after {
    content: ''; /* Required for the pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/h1.png'); /* Change this to your image path */
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the image */
    filter: brightness(40%);
    z-index: -1; /* Ensure the image is behind the content */
}
.main-container::after{
    content: '';
    top: 0;
    left: 0;
}
.overlay {
    width: 100%;
    height: 100vh;
    position: absolute; /* Overlay over the entire container */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 1; /* Place it above the background image */
}

.left, .right {
    flex: 1;
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative; /* Relative positioning for z-index context */
    z-index: 2; /* Ensure content is above the overlay */
    padding-top: 160px;
    align-items: center;
}

.left {
    justify-content: start;
    align-items: flex-start;
    text-align: start;
}

.left .line {
    width: 100%;
    height: 20px;
    justify-content: center;
}

.line .l-line {
    width: 150px;
    height: 5px;
    background:#f88c10;
    margin-top: 20px;
   
}

.left h2, h1, p {
    text-align: start;
    width: 100%; 
    padding: 5px 5px 5px 0;
}

.left h2 {
    text-transform: uppercase;
    font-size: 40px;
    margin-bottom: 0;
    color: #f88c10;
   
}

.left p {
    color: #c4c4c4fb;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 20px;
    padding-top: 40px;
}

.left h1 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 60px;
    line-height: 70px;
    
}

.right {
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
}

.right p {
    text-align: justify;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    padding-bottom: 50px;
    line-height: 40px;
    color: rgb(255, 255, 255);
    
   
}

.right .button {
    line-height: 1;
    text-decoration: none;
    display: inline-flex;
    border: none;
    cursor: pointer;
    align-items: center;
    gap: 0.75rem;
    background-color: #fc7200;
    color: #ffffff;
    font-weight: 600;
    border-radius: 10rem;
    padding: 0.75rem 1.5rem;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    transition: background-color 0.3s;
}

.right .button:hover {
    background-color: #00325c;
}

@media (max-width: 768px) {
    .main-container {
        flex-direction: column;
        height: auto;
        padding: 0;
     
    }

    .right {
        display: flex;
        order: 2;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .right p {
        font-size: 18px;
    }

    .left h1 {
        font-size: 20px;
        line-height: 30px;
    }

    .left p {
        font-size: 18px; 
    }

    .left h2 {
        font-size: 22px;
    }
}

@media (max-width: 1118px) {
    .left h1 {
        font-size: 50px;
    }
}
@media (max-width: 770px){
    .left h1 {
        font-size: 30px;
    }

.right,.left{
    padding-top: 20px;
}
}