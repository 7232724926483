@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* General reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Poppins", sans-serif;
    background: transparent;
    overflow-x: hidden; /* Prevents x-overflow */
}

/* Navbar styles */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Changed to prevent spacing issues */
    width: 100%; /* Ensure navbar does not overflow */
    padding: 5px 15px 0px 15px;
    background-color: white;
    z-index: 20000;
    top: 0;
    box-shadow: -3px -35px 124px -12px rgba(0,0,0,0.64);
    position: sticky;
}

/* Logo section */
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 0.4;
}

.logo img {
    max-width: 1000px; /* Ensures logo doesn't overflow */
    height: 50px;
}

/* Desktop navigation links */
.nav-section {
    display: flex;
    flex: 1;
    justify-content: space-around;
}

.nav-links {
    list-style: none;
    display: flex;
    justify-content: space-around;
    flex: 1;
}

.nav-links li {
    margin: 0 15px;
}

.nav-links a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.nav-links a:hover {
    color: #ff4081;
}

/* Mobile menu icon (hamburger) */
.mobile-menu-icon {
    display: none;
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    padding: 0%;
}
.mobile-menu-icon :hover{
   
}
/* Mobile navigation links */
.mobile-nav {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 250px;
    background-color: #ffffff;
    text-align: center;
    z-index: 999;
}

.mobile-nav-links {
    list-style: none;
}

.mobile-nav-links li {
    margin: 20px 0;
}

.mobile-nav-links a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-family: "Poppins", sans-serif;
}

/* Show mobile menu */
.mobile-nav-links a:hover {
    color: #ff4081;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .navbar {
        position: sticky;
    }
    .nav-links {
        display: none;
    }
    .mobile-menu-icon {
        display: block;
        color: black;
    }
    .mobile-nav {
        display: block;
        height: 100vh;
        top: 62px;
        align-items: center;
        justify-content: start;
        position: fixed;
        background: #ffffff;
        z-index: 999;
    }
    .mobile-nav-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 20px;
    }
    .navbar .overlay {
        top: 65px;
        left: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.582);
        z-index: -1;
    }
}

@media (max-width: 1128px) {
    .nav-links li a {
        font-size: 16px;
    }
}

@media (max-width: 1036px) {
    .nav-links li a {
        font-size: 14px;
    }
}
