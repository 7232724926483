.testimonial-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #002D72; /* Blue background */
  color: white;
  padding: 50px;
  text-align: center;
  position: relative;
  height: 600px;
}

.testimonial-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: #ffffff;
}

.testimonial-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 100%;
  overflow: visible; /* Ensure child elements can overflow */
}

.testimonial-card {
  background-color: rgb(245, 245, 245);
  color: #000;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: left;
  position: relative;
  overflow: visible; 
  height: 300px;/* Allow the profile image to overflow outside the card */
}

.testimonial-profile {
  position: absolute;
  top: -40px; /* Move the profile image above the card */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* Ensure the profile is on top of the card */
}
.testimonial-title p{
  font-size: 20px;
  text-align: center;
}
.testimonial-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid white;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.testimonial-image img{
  width: 100%;
  height: 100%;
}
.testimonial-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-top: 50px; /* Add margin to avoid overlap with profile picture */
  margin-bottom: 20px;
  text-align: center;
}

.testimonial-rating {
  font-size: 1.5rem;
  color: #FFD700; /* Gold color for stars */
}

/* Style the arrows */
.testimonial-slider .arrow {
  font-size: 2rem;
  color: black; /* Set arrow color to black */
  background: white;
  border: none;
  cursor: pointer;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.testimonial-slider .left-arrow {
  left: -100px;
}

.testimonial-slider .right-arrow {
  right: -100px;
}

/* New CSS for dot indicators */
.dot-indicators {
  display: flex;
  justify-content: center; /* Center the dots below the testimonial card */
  margin-top: 20px; /* Space above the dots */
}

.dot {
  height: 12px; /* Dot height */
  width: 12px; /* Dot width */
  margin: 0 5px; /* Spacing between dots */
  background-color: #bbb; /* Inactive dot color */
  border-radius: 50%; /* Make dots circular */
  display: inline-block;
  cursor: pointer; /* Show pointer on hover */
  transition: background-color 0.3s; /* Smooth transition for color change */
}

.dot.active {
  background-color: #FFD700; /* Active dot color */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .testimonial-slider .arrow {
    font-size: 3rem;
    padding: 0 10px;
  }
  .testimonial-slider .left-arrow {
    left: -40px;
  }
  .testimonial-slider .right-arrow {
    right: -40px;
  }
  .testimonial-card {
    padding: 20px;
  }

  .testimonial-profile {
    top: -30px; /* Adjust position for smaller screens */
  }

  .testimonial-image {
    width: 100px;
    height: 100px;
  }

  .testimonial-text {
    font-size: 1rem;
    margin-top: 60px;
  }
}
