/* General Styling */
.mission-vision-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 40px;
    background: linear-gradient(135deg, #283E51 0%, #4B79A1 100%); /* Gradient background */
    min-height: 100vh;
    box-sizing: border-box;
  }
  
  .vision-section, .mission-section {
    padding: 50px;
    background-color: rgba(255, 255, 255, 0.9); /* White with slight transparency */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* Heavier shadow for premium look */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px; /* Smooth, round corners */
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Hover effects */
    border: 1px solid #ccc;
  }
  
  .vision-section:hover, .mission-section:hover {
    transform: scale(1.03); /* Slight zoom on hover */
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.4); /* Enhanced shadow on hover */
  }
  
  .title {
    font-size: 2.8rem; /* Larger, bolder titles */
    color: #0D1B2A;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .vision-content, .mission-content {
    font-size: 1.2rem;
    color: #333;
    text-align: justify;
    line-height: 1.7;
    margin-top: 20px;
  }
  
  .vision-image, .mission-image {
    width: 120px; /* Larger images/icons */
    margin-bottom: 20px;
  }
  
  /* Styling the list in the mission section */
  .mission-content  ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 20px;
  }
  
  .mission-content ul li {
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
    font-weight: bold;
    color: #0D1B2A;
  }
  
  .mission-content ul li::before {
    content: '✓';
    position: absolute;
    left: 0;
    top: 0;
    color: #27AE60; /* Green checkmark */
    font-size: 24px;
    font-weight: bold;
  }
  
  p {
    margin: 10px 0;
    color: #444;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 1024px) {
    .mission-vision-container {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .vision-section, .mission-section {
      padding: 30px;
    }
  
    .vision-content, .mission-content {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 1.6rem;
    }
  
    .vision-section, .mission-section {
      padding: 20px;
    }
  
    .vision-content, .mission-content {
      font-size: 0.9rem;
    }
  
    ul li::before {
      font-size: 18px;
    }
  }
  