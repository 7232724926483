@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.slider-container{
    position: relative;
    width: 100%;
    height: 90vh;
    min-height: 700px;
    display: flex;
    flex-direction: column;                                      
    align-items: flex-start;
    justify-content: center;
}
.slider-container::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets//About/modetn-city.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(40%);
    z-index: -1;
}

.slider-container .slider{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.slider-container .slider .left{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    margin: 0;
    gap: 20px;
}
.slider-container .slider .left .heading{
    width: 100%;
    height:auto;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content:center;
   
}
.slider-container .slider .left .heading h1{
    color: white;
    font-size: 3.5em;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.slider-container .slider .left .sub-heading{
    width: 100%;
    height:auto;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 100;
    font-style: normal;
   
}
.slider-container .slider .left .sub-heading h3{
    color: rgb(255, 255, 255);
    font-size: 1.9em;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 100;
    font-style: normal;
}
.slider-container .slider .left .active-btn{
    width: 100%;
    height: auto;
    padding: 10px 40px;
}

.slider-container .slider .left .btn{
    position: relative;
    width: 180px;;
    height: 50px;
    padding: 0px 0px;
    background: #017ce0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    font-size: 18px;
    font-weight: 600;
}
.slider-container .slider .left .btn::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a0c0f;
    transform:scaleX(0)rotate(120deg);
    z-index: -1;
    transition: all 0.5s;
}
.slider-container .slider .left .btn:hover::after{
    transform:scaleX(1)rotate(0deg);
    transition: all 0.5s;
}
.slider-container .slider .right-site{
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
}
.slider-container .slider .right-site .img{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:flex-end;
    justify-content: flex-start;
    margin: 0;
}
.slider-container .slider .right-site .img img{
    width: 100%;
    transform:translate(-130px,200px);
}

/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .slider-container{
        position: relative;
        width: 100%;
        height: 90vh;
        min-height: 650px;
    }

    .slider-container .slider{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .slider-container .slider .left{
        width: 100%;
        gap: 0px;
    }
    .slider-container .slider .left .heading{
        width: 100%;
        height:auto;
        padding: 6px 10px;
       
    }
    .slider-container .slider .left .heading h1{
        font-size: 1.5em;
    }
    
    .slider-container .slider .left .sub-heading{
        width: 100%;
        height:auto;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content:flex-start;
       
    }
    .slider-container .slider .left .sub-heading h3{
        font-size: 1em;
    }
    .slider-container .slider .left .active-btn{
        width: 100%;
        height: auto;
        padding: 1px 10px;
    }
    
    .slider-container .slider .left .btn{
        position: relative;
        width: 140px;;
        height: 40px;
        font-size: 15px;
    }


    .slider-container .slider .right-site{
        width: 100%;
        height:30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
    }
    .slider-container .slider .right-site .img{
        width: 100%;
        height: 100%;
        display: flex;
        align-items:flex-end;
        justify-content:center;
        margin: 0;
    }
    .slider-container .slider .right-site .img img{
        width: 320px;
        transform:translate(0px,180px);
    }
    
  }
  
  /* Normal mobile devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .slider-container{
        position: relative;
        width: 100%;
        height: 90vh;
        min-height: 650px;
    }

    .slider-container .slider{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .slider-container .slider .left{
        width: 100%;
        gap: 0px;
    }
    .slider-container .slider .left .heading{
        width: 100%;
        height:auto;
        padding: 6px 10px;
       
    }
    .slider-container .slider .left .heading h1{
        font-size: 1.5em;
    }
    
    .slider-container .slider .left .sub-heading{
        width: 100%;
        height:auto;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content:flex-start;
       
    }
    .slider-container .slider .left .sub-heading h3{
        font-size: 1em;
    }
    .slider-container .slider .left .active-btn{
        width: 100%;
        height: auto;
        padding: 1px 10px;
    }
    
    .slider-container .slider .left .btn{
        position: relative;
        width: 140px;;
        height: 40px;
        font-size: 15px;
    }


    .slider-container .slider .right-site{
        width: 100%;
        height:30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
    }
    .slider-container .slider .right-site .img{
        width: 100%;
        height: 100%;
        display: flex;
        align-items:flex-end;
        justify-content:center;
        margin: 0;
    }
    .slider-container .slider .right-site .img img{
        width: 260px;
        transform:translate(0px,0px);
    }
  }
  
  /* Tablets and iPads (portrait and landscape, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .slider-container{
        position: relative;
        width: 100%;
        height: 90vh;
        min-height: 650px;
    }

    .slider-container .slider{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .slider-container .slider .left{
        width: 60%;
        gap: 0px;
        height:auto;
        min-height:60%;
        justify-content: flex-start;
    }
    .slider-container .slider .left .heading{
        width: 100%;
        height:auto;
        padding: 6px 10px;
       
    }
    .slider-container .slider .left .heading h1{
        font-size: 2em;
    }
    
    .slider-container .slider .left .sub-heading{
        width: 100%;
        height:auto;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content:flex-start;
       
    }
    .slider-container .slider .left .sub-heading h3{
        font-size: 1.5em;
    }
    .slider-container .slider .left .active-btn{
        width: 100%;
        height: auto;
        padding: 1px 10px;
    }
    
    .slider-container .slider .left .btn{
        position: relative;
        width: 170px;;
        height: 50px;
        font-size: 16px;
    }


    .slider-container .slider .right-site{
        width: 30%;
        height:30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
    }
    .slider-container .slider .right-site .img{
        width: 100%;
        height: 100%;
        display: flex;
        align-items:flex-end;
        justify-content:center;
        margin: 0;
    }
    .slider-container .slider .right-site .img img{
        width: 520px;
        transform:translate(-50px,60%);
    }
  }
  
  /* Tablets and iPads (landscape, larger than 992px) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .slider-container{
        position: relative;
        width: 100%;
        height: 90vh;
        min-height: 650px;
    }

    .slider-container .slider{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .slider-container .slider .left{
        width: 70%;
        gap: 0px;
        height:auto;
        min-height:60%;
        justify-content: flex-start;
    }
    .slider-container .slider .left .heading{
        width: 100%;
        height:auto;
        padding: 6px 10px;
       
    }
    .slider-container .slider .left .heading h1{
        font-size: 2.5em;
    }
    
    .slider-container .slider .left .sub-heading{
        width: 100%;
        height:auto;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content:flex-start;
       
    }
    .slider-container .slider .left .sub-heading h3{
        font-size: 1.5em;
    }
    .slider-container .slider .left .active-btn{
        width: 100%;
        height: auto;
        padding: 1px 10px;
    }
    
    .slider-container .slider .left .btn{
        position: relative;
        width: 180px;
        height: 45px;
        font-size: 18px;
    }


    .slider-container .slider .right-site{
        width: 30%;
        height:30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
    }
    .slider-container .slider .right-site .img{
        width: 100%;
        height: 100%;
        display: flex;
        align-items:flex-end;
        justify-content:center;
        margin: 0;
    }
    .slider-container .slider .right-site .img img{
        width: 520px;
        transform:translate(-40px,60%);
    }
  }
  
  /* Laptops and PCs (larger than 1200px) */
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    .slider-container{
        position: relative;
        width: 100%;
        height: 90vh;
        min-height: 650px;
    }

    .slider-container .slider{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .slider-container .slider .left{
        width: 70%;
        gap: 0px;
        height:auto;
        min-height:60%;
        justify-content: flex-start;
    }
    .slider-container .slider .left .heading{
        width: 100%;
        height:auto;
        padding: 6px 10px;
       
    }
    .slider-container .slider .left .heading h1{
        font-size: 3.3em;
    }
    
    .slider-container .slider .left .sub-heading{
        width: 100%;
        height:auto;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content:flex-start;
       
    }
    .slider-container .slider .left .sub-heading h3{
        font-size: 2em;
    }
    .slider-container .slider .left .active-btn{
        width: 100%;
        height: auto;
        padding: 1px 10px;
    }
    
    .slider-container .slider .left .btn{
        position: relative;
        width: 250px;
        height: 60px;
        font-size: 18px;
    }


    .slider-container .slider .right-site{
        width: 30%;
        height:30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0;
    }
    .slider-container .slider .right-site .img{
        width: 100%;
        height: 100%;
        display: flex;
        align-items:flex-end;
        justify-content:center;
        margin: 0;
    }
    .slider-container .slider .right-site .img img{
        width: 530px;
        transform:translate(-50px,50%);
    }
  }
  
