/* General container */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.bb {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 4% 15%;
  justify-content: center;
 align-items: center;
  font-family: "Poppins", sans-serif;
  background: #006a8a;
  
}


.title-container{
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.title-container .title-under{
  display: flex;
width: 100%;
height: auto;
justify-content: center;
gap: 0px;
}
.title-under .title-line{
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 3px;
  background:black;
}
.title-under .title-image{
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-image img{
  width: 100%;
  height: auto;
}
.title-head h1{
text-align: center;
color: white;
text-transform: uppercase;

}
/* Service Grid */
.service-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 30%);
  gap: 20px;
  padding: 20px;
}

/* Service Card */
.service-card {
  text-align: center;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background:white;
  box-shadow: 1px 1px 10px black;
  position: relative;
  transition: background-color 0.3s ease-in-out;
}

/* Hover effect on card */
.service-card:hover {
  background: #ffffffe7;

}
.service-card:hover .button1{
  background: #0056b3;
}
.service-card:hover .service-card p{
  color: rgb(0, 0, 0);
}
/* Image container */
.service-image {
  position: relative;
  width: 100px;
  height: 100px;
  background: transparent;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Default Image */
.default-image {
  width: 100%;  /* Full width of the container */
  height: 100%; /* Full height of the container */
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  position: absolute; /* Absolute positioning to layer images */
  top: 0;
  left: 0;
  z-index: 1;
}

/* Hover Image (initially hidden) */
.hover-image {
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute; /* Layered over the default image */
  top: 0;
  left: 0;
  z-index: 2; /* Higher z-index to appear above the default image */
}

/* Swap the images on hover */
.service-card:hover .default-image {
  opacity: 0;
}

.service-card:hover .hover-image {
  opacity: 1;
}

/* Service Title and Description */
h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #0056b3;
}

.service-card p {
  font-size: 14px;
  color: #000000;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Button */
.button1 {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  border: none;
  cursor: pointer;
  align-items: center;
  gap: 0.75rem;
  background-color: #fc7200;
  color: #fff;
  border-radius: 10rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.button__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: var(--clr);
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.button1:hover {
  background-color: #0056b3;
}

.button1:hover .button__icon-wrapper {
  color: #0056b3;
}

.button__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.button1:hover .button__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.button1:hover .button__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

.description {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.description p {
  text-align: center;
  font-size: 20px;
  color: white;

}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .service-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .bb {
height: 100%;
  }
  .bb {
    padding: 0% 3%;
  }
  .title-head h1{

    color: white;
    font-size: 26px;
    }
}

@media (max-width: 480px) {
  .service-grid {
    grid-template-columns: 1fr;
  }

  .bb {
    padding: 0% 3%;
  }
  .title-head h1{

    color: white;
    font-size: 19px;
    }
}
@media (max-width: 360px) {
  .title-head h1{

    color: white;
    font-size: 17px;
    }
}
@media (max-width: 360px) {
  .title-head h1{

    color: white;
    font-size: 15px;
    }
}
