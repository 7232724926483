@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

.request-quote-container {
  min-height: 100vh;
  background-image: url('../assets/land.jpg'); /* Update the path to your image */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  background-color:transparent; /* Semi-transparent background */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px; /* Max width for larger screens */
}

form {
  display: flex;
  flex-direction: column;
}

input,
textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background: transparent;

}
textarea::placeholder{
  color: white;
  font-family: "Poppins", sans-serif;
}
input::placeholder{
  color: white;
  font-family: "Poppins", sans-serif;
}
button {
  padding: 10px;
  background-color: #1a73e8;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

 form button:hover {
  background-color: #0056c0;
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    height: 100vh;
  }
  
  .request-quote-container {
    min-height: 100vh;
    background-image: url('../assets/land.jpg'); /* Update the path to your image */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .form-container {
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 500px; /* Max width for larger screens */
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input,
  textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  button {
    padding: 10px;
    background-color: #1a73e8;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #0056c0;
  }
  
  .success-message {
    text-align: center;
    color: #4caf50;
  }
  
  .success-image {
    max-width: 100px; /* Adjust size as needed */
    margin-bottom: 20px; /* Space below the image */
  }
  
}

.success-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #4caf50;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.success-message h3{
  color: #4caf50;
  font-size: 1.2rem;
}
.success-message p{
  text-align: center;
  font-size: 1rem;
  color: #fafafa;
}

.success-image{
  width: 300px;
  height: 300px;
}
